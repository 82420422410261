import React from "react";

export default function Footer() {
  return (
    <div className="bg-green-700 sticky top-0 shadow-md border-t">
      <div className="max-w-[95%] lg:max-w-[1080px] mx-auto py-10">
        <div className="flex justify-between pb-10">
            <div className="text-start font-bold text-white">YourLand</div>
            <div className="text-end">Contact Us</div>
        </div>
        <hr />
        <div className="text-center pt-10">
        Copyright © 2024 YourLand Construction. All Rights Reserved.
        </div>
      </div>
    </div>
  );
}
