import React from "react";
import Header from "./Header";
import Welcome from "./Welcome";
import Experience from "./Experience";
import Footer from "./Footer";
import Offer from "./Offer";
import bg_img from "../assets/images/welcome_bg.jpg";

export default function Home() {
  const token = localStorage.getItem('m_g_a');
  return (
    <div
      style={{
        background: `url(${bg_img}) no-repeat fixed`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Header />
      <Welcome />
      <Experience />
      {
        token && <Offer />
      }
      <Footer />
    </div>
  );
}
