import React from 'react'

export default function Experience() {
  return (
    <div className='bg-green-700 text-white'>
        <div className="max-w-[95%] lg:max-w-[1080px] mx-auto grid grid-flow-row grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 py-12">
            <div className='flex justify-center items-center gap-3 mb-5 lg:mb-0'>
                <p className='text-4xl font-semibold'>25+</p>
                <p>Years Of Experience</p>
            </div>
            <div className='flex justify-center items-center gap-3 mb-5 lg:mb-0'>
                <p className='text-4xl font-semibold'>378+</p>
                <p>Projects Complete</p>
            </div>
            <div className='flex justify-center items-center gap-3 mb-5 md:mb-0'>
                <p className='text-4xl font-semibold'>500+</p>
                <p>Wining Global Award</p>
            </div>
            <div className='flex justify-center items-center gap-3 mb-5 md:mb-0'>
                <p className='text-4xl font-semibold'>1000+</p>
                <p>Certified Clients</p>
            </div>
        </div>
    </div>
  )
}
