export const preconstruction = [
  {
    title: "Project Planning and Design",
    details: [
      "Conceptual Design: Development of initial ideas and concepts.",
      "Detailed Design: Creation of detailed architectural and engineering plans.",
      "Feasibility Studies: Analysis of the project’s viability, including technical, economic, and legal aspects.",
    ],
  },
  {
    title: "Cost Estimation and Budgeting",
    details: [
      "Preliminary Cost Estimates: Rough cost estimates based on initial designs.",
      "Detailed Cost Estimates: More precise costs based on detailed plans.",
      "Value Engineering: Identifying cost-saving measures without compromising quality.",
    ],
  },
  {
    title: "Scheduling",
    details: [
      "Master Schedule: Development of an overall project timeline.",
      "Phased Scheduling: Detailed timelines for various project phases.",
    ],
  },
  // {
  //   "title": "Permitting and Approvals",
  //   "details": [
  //     "Regulatory Compliance: Ensuring the project meets local, state, and federal regulations.",
  //     "Permits: Securing necessary construction permits and approvals."
  //   ]
  // },
  // {
  //   "title": "Risk Management",
  //   "details": [
  //     "Risk Identification: Identifying potential risks and their impacts.",
  //     "Mitigation Strategies: Developing plans to mitigate identified risks."
  //   ]
  // },
  // {
  //   "title": "Procurement Planning",
  //   "details": [
  //     "Material Procurement: Planning for the acquisition of necessary materials.",
  //     "Contractor Selection: Choosing subcontractors and suppliers."
  //   ]
  // },
  // {
  //   "title": "Site Analysis and Preparation",
  //   "details": [
  //     "Site Surveys: Conducting geological and environmental surveys.",
  //     "Site Logistics: Planning for site access, storage, and staging areas."
  //   ]
  // },
];

export const construction = [
  {
    title: "Project Planning",
    details: [
      "Defining project scope and objectives.",
      "Creating a project plan with timelines and milestones.",
      "Allocating resources and assigning tasks.",
    ],
  },
  {
    title: "Site Preparation",
    details: [
      "Conducting site surveys and soil testing.",
      "Clearing and leveling the construction site.",
      "Setting up site access and temporary facilities.",
    ],
  },
  {
    title: "Quality Control",
    details: [
      "Establishing quality standards and procedures.",
      "Conducting regular inspections and audits.",
      "Implementing corrective actions for defects and non-conformities.",
    ],
  },
];

export const reconstruction = [
  {
    title: "Assessment and Evaluation",
    details: [
      "Conducting a thorough inspection of existing structures.",
      "Identifying areas that need repair or replacement.",
      "Evaluating structural integrity and safety.",
    ],
  },
  {
    title: "Design and Planning",
    details: [
      "Developing detailed reconstruction plans and drawings.",
      "Selecting appropriate materials and methods for reconstruction.",
      "Creating a timeline and budget for the project.",
    ],
  },
  {
    title: "Execution and Quality Control",
    details: [
      "Implementing reconstruction plans according to design specifications.",
      "Conducting regular quality inspections during the reconstruction process.",
      "Ensuring compliance with safety standards and regulations.",
    ],
  },
];

export const decoration = [
  {
    "title": "Concept Development",
    "details": [
      "Identifying the purpose and theme of the decoration.",
      "Creating mood boards and design concepts.",
      "Selecting color schemes, materials, and styles."
    ]
  },
  {
    "title": "Material Selection",
    "details": [
      "Choosing appropriate materials for walls, floors, and furnishings.",
      "Selecting decorative elements such as lighting, textiles, and accessories.",
      "Ensuring materials align with the overall design concept and budget."
    ]
  },
  {
    "title": "Implementation and Styling",
    "details": [
      "Executing the decoration plan according to the design specifications.",
      "Arranging furniture and decor items for optimal aesthetics and functionality.",
      "Making final adjustments and touches to achieve the desired look."
    ]
  }
]

export const safety = [
  {
    "title": "Fire Safety",
    "details": [
      "Installing smoke detectors in key areas of the home.",
      "Keeping fire extinguishers accessible and knowing how to use them.",
      "Creating and practicing a fire evacuation plan with family members."
    ]
  },
  {
    "title": "Security Measures",
    "details": [
      "Installing security systems, including alarms and surveillance cameras.",
      "Using strong locks on doors and windows.",
      "Implementing outdoor lighting to deter intruders."
    ]
  },
  {
    "title": "Childproofing",
    "details": [
      "Installing safety gates to block off stairs and dangerous areas.",
      "Securing furniture and appliances to prevent tip-overs.",
      "Keeping hazardous substances out of reach and locked away."
    ]
  }
]

export const special = [
  {
    "title": "Financial Planning",
    "details": [
      "Assessing current financial situation and setting goals.",
      "Creating a budget and saving plan.",
      "Developing investment strategies tailored to individual needs."
    ]
  },
  {
    "title": "Career Guidance",
    "details": [
      "Identifying strengths and career interests.",
      "Exploring potential career paths and opportunities.",
      "Providing resume writing and interview preparation assistance."
    ]
  },
  {
    "title": "Health and Wellness",
    "details": [
      "Creating personalized fitness and nutrition plans.",
      "Providing stress management techniques and resources.",
      "Advising on mental health support and resources."
    ]
  }
]