import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { decodeData, getMethod } from "../services";
import { USER_INFO_API } from "../services/api";
import { userInfoHandler } from "../store/slices/userInfoSlice";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { FiLogOut } from "react-icons/fi";
import { FaUserCheck } from "react-icons/fa";
import logo_img from '../assets/images/logo/logo.jpg';

export default function Header() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const token = localStorage.getItem('m_g_a');
    const { user_info } = useSelector((state) => state.userInfoSlice);

    const fetchUserInfo = async () => {
        const token = decodeData(localStorage.getItem("m_g_a"));
        if (!token) return;
        setIsLoading(true);
        await getMethod(USER_INFO_API + `/${token?.user_id}`, token?.token).then(
          (res) => {
            setIsLoading(false);
            if (res?.status === "success") {
              dispatch(userInfoHandler(res?.data));
            } else {
              toast.error(res?.message || "Something went wrong!");
            }
          }
        );
      };
    
      useEffect(() => {
        if (user_info?.reload) {
          fetchUserInfo();
        }
        if (!user_info?.id) {
          fetchUserInfo();
        }
      }, [user_info]);

  return (
    <div className="bg-green-700 sticky top-0 shadow-md z-[9]">
      <div className="flex justify-between items-center max-w-[95%] lg:max-w-[1080px] mx-auto py-3">
        <div className="text-start font-bold text-white cursor-pointer" onClick={() => navigate('/')}>
          <img src={logo_img} alt="Logo" className="w-16 rounded-lg shadow-md" />
        </div>
        {
            token ?
            <div className="text-end flex items-center">
                <div className="text-end text-white font-semibold flex items-center gap-3">
                    <FaUserCheck className="text-2xl" />
                    {user_info?.name}
                </div>
                <span className="text-3xl mx-1 text-slate-600">|</span>
                <button className="bg-white px-3 py-1 text-green-600 rounded-sm" onClick={() => navigate('/contact-form')}>Contact Form</button>
                <span className="text-3xl mx-1 text-slate-600">|</span>
                <FiLogOut className="text-2xl text-red-600 cursor-pointer" title="Logout" onClick={() => {
                  localStorage.removeItem('m_g_a');
                  window.location.reload();
                }} />
            </div> :
          <div className="text-end">
              <span className="hover:underline cursor-pointer text-white font-semibold" onClick={() => navigate('/signin')}>Sign In</span>
              <span className="text-3xl mx-1 text-slate-600">|</span>
              <span className="hover:underline cursor-pointer text-white font-semibold" onClick={() => navigate('/register')}>Sign Up</span>
          </div>
        }
      </div>
    </div>
  );
}
