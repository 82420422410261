import React from "react";
import pre_01 from "../assets/images/offer/pre_01.webp";
import pre_02 from "../assets/images/offer/pre_02.jpg";
import pre_03 from "../assets/images/offer/pre_03.png";
import build_01 from "../assets/images/offer/build_01.jpg";
import build_02 from "../assets/images/offer/build_02.jpg";
import build_03 from "../assets/images/offer/build_03.webp";
import re_01 from "../assets/images/offer/re_01.jpg";
import re_02 from "../assets/images/offer/re_02.jpeg";
import re_03 from "../assets/images/offer/re_03.jpg";
import de_01 from "../assets/images/offer/de_01.jpg";
import de_02 from "../assets/images/offer/de_02.png";
import de_03 from "../assets/images/offer/de_03.webp";
import safe_01 from "../assets/images/offer/safe_01.jpg";
import safe_02 from "../assets/images/offer/safe_04.jpeg";
import safe_03 from "../assets/images/offer/safe_05.avif";
import sp_01 from "../assets/images/offer/sp_01.webp";
import sp_02 from "../assets/images/offer/sp_02.jpg";
import sp_03 from "../assets/images/offer/sp_03.jpg";
import { construction, decoration, preconstruction, reconstruction, safety, special } from "../store/data";

export default function OfferPhoto({ show }) {
  if (show === "01") {
    return (
      <div className="pt-5">
        <div className="flex items-center gap-3 mb-3">
          <p className="text-green-500 font-semibold whitespace-nowrap text-xl">
            Preconstruction
          </p>
          <div className="h-1 bg-green-700 w-full"></div>
        </div>
        <div className="grid grid-flow-row grid-cols-1 md:grid-cols-2 gap-3">
          <div>
            <img className="rounded-lg shadow-md mb-3" src={pre_01}></img>
            <div className="grid grid-flow-row grid-cols-2 gap-3">
              <img
                className="rounded-lg shadow-md h-[160px] w-full"
                src={pre_02}
              ></img>
              <img
                className="rounded-lg shadow-md h-[160px] w-full"
                src={pre_03}
              ></img>
            </div>
          </div>
          <div>
            <ul className="ps-3">
              {preconstruction?.map((el, i) => (
                <span key={i}>
                  <li className="list-item list-inside list-decimal font-medium">
                    {el?.title}
                  </li>
                  <ul className="ps-3 mb-3">
                    {el?.details?.map((el1, i) => (
                      <li
                        key={i + "_child"}
                        className="list-item list-inside list-disc"
                      >
                        {el1}
                      </li>
                    ))}
                  </ul>
                </span>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
  if (show === "02") {
    return (
      <div className="pt-5">
        <div className="flex items-center gap-3 mb-3">
          <p className="text-green-500 font-semibold whitespace-nowrap text-xl">
            Construction
          </p>
          <div className="h-1 bg-green-700 w-full"></div>
        </div>
        <div className="grid grid-flow-row grid-cols-1 md:grid-cols-2 gap-3">
          <div>
            <img className="rounded-lg shadow-md mb-3" src={build_03}></img>
            <div className="grid grid-flow-row grid-cols-2 gap-3">
              <img
                className="rounded-lg shadow-md h-[160px] w-full"
                src={build_02}
              ></img>
              <img
                className="rounded-lg shadow-md h-[160px] w-full"
                src={build_01}
              ></img>
            </div>
          </div>
          <div>
            <ul className="ps-3">
              {construction?.map((el, i) => (
                <span key={i}>
                  <li className="list-item list-inside list-decimal font-medium">
                    {el?.title}
                  </li>
                  <ul className="ps-3 mb-3">
                    {el?.details?.map((el1, i) => (
                      <li
                        key={i + "_child"}
                        className="list-item list-inside list-disc"
                      >
                        {el1}
                      </li>
                    ))}
                  </ul>
                </span>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
  if (show === "03") {
    return (
      <div className="pt-5">
        <div className="flex items-center gap-3 mb-3">
          <p className="text-green-500 font-semibold whitespace-nowrap text-xl">
            Reconstruction
          </p>
          <div className="h-1 bg-green-700 w-full"></div>
        </div>
        <div className="grid grid-flow-row grid-cols-1 md:grid-cols-2 gap-3">
          <div>
            <img className="rounded-lg shadow-md mb-3" src={re_01}></img>
            <div className="grid grid-flow-row grid-cols-2 gap-3">
              <img
                className="rounded-lg shadow-md h-[160px] w-full"
                src={re_02}
              ></img>
              <img
                className="rounded-lg shadow-md h-[160px] w-full"
                src={re_03}
              ></img>
            </div>
          </div>
          <div>
            <ul className="ps-3">
              {reconstruction?.map((el, i) => (
                <span key={i}>
                  <li className="list-item list-inside list-decimal font-medium">
                    {el?.title}
                  </li>
                  <ul className="ps-3 mb-3">
                    {el?.details?.map((el1, i) => (
                      <li
                        key={i + "_child"}
                        className="list-item list-inside list-disc"
                      >
                        {el1}
                      </li>
                    ))}
                  </ul>
                </span>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
  if (show === "04") {
    return (
      <div className="pt-5">
        <div className="flex items-center gap-3 mb-3">
          <p className="text-green-500 font-semibold whitespace-nowrap text-xl">
            Decoration
          </p>
          <div className="h-1 bg-green-700 w-full"></div>
        </div>
        <div className="grid grid-flow-row grid-cols-1 md:grid-cols-2 gap-3">
          <div>
            <img className="rounded-lg shadow-md mb-3" src={de_03}></img>
            <div className="grid grid-flow-row grid-cols-2 gap-3">
              <img
                className="rounded-lg shadow-md h-[160px] w-full"
                src={de_02}
              ></img>
              <img
                className="rounded-lg shadow-md h-[160px] w-full"
                src={de_01}
              ></img>
            </div>
          </div>
          <div>
            <ul className="ps-3">
              {decoration?.map((el, i) => (
                <span key={i}>
                  <li className="list-item list-inside list-decimal font-medium">
                    {el?.title}
                  </li>
                  <ul className="ps-3 mb-3">
                    {el?.details?.map((el1, i) => (
                      <li
                        key={i + "_child"}
                        className="list-item list-inside list-disc"
                      >
                        {el1}
                      </li>
                    ))}
                  </ul>
                </span>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
  if (show === "05") {
    return (
      <div className="pt-5">
        <div className="flex items-center gap-3 mb-3">
          <p className="text-green-500 font-semibold whitespace-nowrap text-xl">
            Safety
          </p>
          <div className="h-1 bg-green-700 w-full"></div>
        </div>
        <div className="grid grid-flow-row grid-cols-1 md:grid-cols-2 gap-3">
          <div>
            <img className="rounded-lg shadow-md mb-3" src={safe_01}></img>
            <div className="grid grid-flow-row grid-cols-2 gap-3">
              <img
                className="rounded-lg shadow-md h-[160px] w-full"
                src={safe_02}
              ></img>
              <img
                className="rounded-lg shadow-md h-[160px] w-full"
                src={safe_03}
              ></img>
            </div>
          </div>
          <div>
            <ul className="ps-3">
              {safety?.map((el, i) => (
                <span key={i}>
                  <li className="list-item list-inside list-decimal font-medium">
                    {el?.title}
                  </li>
                  <ul className="ps-3 mb-3">
                    {el?.details?.map((el1, i) => (
                      <li
                        key={i + "_child"}
                        className="list-item list-inside list-disc"
                      >
                        {el1}
                      </li>
                    ))}
                  </ul>
                </span>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
  if (show === "06") {
    return (
      <div className="pt-5">
        <div className="flex items-center gap-3 mb-3">
          <p className="text-green-500 font-semibold whitespace-nowrap text-xl">
            Special
          </p>
          <div className="h-1 bg-green-700 w-full"></div>
        </div>
        <div className="grid grid-flow-row grid-cols-1 md:grid-cols-2 gap-3">
          <div>
            <img className="rounded-lg shadow-md mb-3" src={sp_01}></img>
            <div className="grid grid-flow-row grid-cols-2 gap-3">
              <img
                className="rounded-lg shadow-md h-[160px] w-full"
                src={sp_02}
              ></img>
              <img
                className="rounded-lg shadow-md h-[160px] w-full"
                src={sp_03}
              ></img>
            </div>
          </div>
          <div>
            <ul className="ps-3">
              {special?.map((el, i) => (
                <span key={i}>
                  <li className="list-item list-inside list-decimal font-medium">
                    {el?.title}
                  </li>
                  <ul className="ps-3 mb-3">
                    {el?.details?.map((el1, i) => (
                      <li
                        key={i + "_child"}
                        className="list-item list-inside list-disc"
                      >
                        {el1}
                      </li>
                    ))}
                  </ul>
                </span>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
