import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Home from "../components/Home";
import Register from "../components/Register";
import Signin from "../components/Signin";
import ContactForm from "../components/ContactForm";
import RouteGuard from "./RouteGuard";

export default function Routers() {
    return (
        <Router>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/signin' element={<Signin />} />
                <Route path='/register' element={<Register />} />
                <Route path='/contact-form' element={<RouteGuard><ContactForm /></RouteGuard>} />
                <Route path='*' element={<Home />} />
            </Routes>
        </Router>
    )
}