import React from "react";
// import bg_img from "../assets/images/welcome_bg.jpg";

export default function Welcome() {
  return (
    <div
      className="bg-transparent"
    //   style={{
    //     backgroundImage: `url(${bg_img})`,
    //     backgroundSize: "cover",
    //     backgroundPosition: "center",
    //   }}
    >
      <div style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
        <div className="max-w-[95%] lg:max-w-[1080px] mx-auto h-[90vh] md:h-[90vh] flex flex-col justify-center items-center gap-5">
          <h3 className="text-3xl md:text-5xl text-white font-semibold">
            Welcome to YourLand Build
          </h3>
          <h3 className="text-3xl md:text-5xl text-white text-center font-semibold">
            Your construction company in Myanmar.
          </h3>
          <p className="text-white text-center">
            We Provide The Best Architectural Design, Construction, And Building
            Maintance Services For You.
          </p>
          <button onClick={() => {
            window.scrollTo({
                top: 1000,
                behavior: 'smooth',
              });
          }} className="py-3 px-5 bg-green-700 font-semibold rounded-lg text-white">
            Our Service
          </button>
        </div>
      </div>
    </div>
  );
}
