import React, { useState } from "react";
import ruler_img from '../assets/images/icons/ruler.png';
import construction_img from '../assets/images/icons/construction.png';
import re_construction_img from '../assets/images/icons/paint-roller.png';
import special_img from '../assets/images/icons/advice.png';
import decoration_img from '../assets/images/icons/shelf.png';
import safty_img from '../assets/images/icons/patient.png';
import OfferPhoto from "./OfferPhoto";

export default function Offer() {
    const [show, setShow] = useState('01');

  return (
    <div className="bg-green-50">
      <div className="max-w-[95%] lg:max-w-[1080px] mx-auto py-12">
        <div className="flex items-center gap-3 mb-3">
            <p className="text-green-500 font-semibold whitespace-nowrap text-lg">What we offer</p>
            <div className="h-1 bg-green-700 w-full lg:max-w-[200px]"></div>
        </div>
        <div className="mb-5">
            <p className="text-3xl font-semibold mb-3">
                OUR PROFESSIONALS <br /> CONSTRUCTION SERVICES
            </p>
            <p className="text-slate-800">
                YourLand Construction is your trusted partner in building dreams and turning visions into reality. Whether you're looking to extend your existing space . . .
            </p>
        </div>
        <div className="grid grid-flow-row grid-cols-2 md:grid-cols-4 gap-3 mb-5">
            <div className={`bg-green-100 rounded-xl shadow-md border-4 border-white p-3 cursor-pointer hover:shadow-lg ${show === '01' ? 'bg-green-400' : ''}`} onClick={() => { setShow('01') }}>
                <p className="text-3xl font-semibold text-slate-900 mb-5">01</p>
                <img src={ruler_img} width={50} alt="Ruler" className="mb-2" />
                <p>Preconstruction</p>
            </div>
            <div className={`bg-green-100 rounded-xl shadow-md border-4 border-white p-3 cursor-pointer hover:shadow-lg ${show === '02' ? 'bg-green-400' : ''}`} onClick={() => { setShow('02') }}>
                <p className="text-3xl font-semibold text-slate-900 mb-5">02</p>
                <img src={construction_img} width={50} alt="Ruler" className="mb-2" />
                <p>Construction</p>
            </div>
            <div className={`bg-green-100 rounded-xl shadow-md border-4 border-white p-3 cursor-pointer hover:shadow-lg ${show === '03' ? 'bg-green-400' : ''}`} onClick={() => { setShow('03') }}>
                <p className="text-3xl font-semibold text-slate-900 mb-5">03</p>
                <img src={re_construction_img} width={50} alt="Ruler" className="mb-2" />
                <p>Reconstruction</p>
            </div>
            <div className={`bg-green-100 rounded-xl shadow-md border-4 border-white p-3 cursor-pointer hover:shadow-lg ${show === '04' ? 'bg-green-400' : ''}`} onClick={() => { setShow('04') }}>
                <p className="text-3xl font-semibold text-slate-900 mb-5">04</p>
                <img src={decoration_img} width={50} alt="Ruler" className="mb-2" />
                <p>Decoration</p>
            </div>
            <div className={`bg-green-100 rounded-xl shadow-md border-4 border-white p-3 cursor-pointer hover:shadow-lg ${show === '05' ? 'bg-green-400' : ''}`} onClick={() => { setShow('05') }}>
                <p className="text-3xl font-semibold text-slate-900 mb-5">05</p>
                <img src={safty_img} width={50} alt="Ruler" className="mb-2" />
                <p>Safty</p>
            </div>
            <div className={`bg-green-100 rounded-xl shadow-md border-4 border-white p-3 cursor-pointer hover:shadow-lg ${show === '06' ? 'bg-green-400' : ''}`} onClick={() => { setShow('06') }}>
                <p className="text-3xl font-semibold text-slate-900 mb-5">06</p>
                <img src={special_img} width={50} alt="Ruler" className="mb-2" />
                <p>Special</p>
            </div>
        </div>
        <OfferPhoto show={show} />
      </div>
    </div>
  );
}
